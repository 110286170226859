/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    font-display: optional;
    @apply min-h-[600px];
    @apply min-w-80;
    @apply bg-gray-100 dark:bggradientlanding font-jetbrains;
    scroll-padding-top: 80px;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf");
    font-weight: 100;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Medium.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
    font-display: block;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 13px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fafafa;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 2.5px solid #fafafa;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
}

@layer utilities {
  .darkscrollbar::-webkit-scrollbar {
    width: 10px;
  }

  .darkscrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #1c2435;
  }

  .darkscrollbar::-webkit-scrollbar-thumb {
    background: #2c3447;
    border-radius: 100vh;
    border: 2.5px solid #2c3447;
  }

  .darkscrollbar::-webkit-scrollbar-thumb:hover {
    background: #333d53;
  }
}

@layer utilities {
  .bggradient {
    @apply bg-gradient-to-r from-newdarkmode-800 to-newdarkmode-700;
  }
  .bggradientlanding {
    @apply bg-gradient-to-r from-newdarkmode-900 to-newdarkmode-800;
  }
  .bggradientdown {
    @apply bg-gradient-to-b from-newdarkmode-800 to-newdarkmode-700;
  }
  .lightbggradient {
    @apply bg-gradient-to-r from-gray-300 to-gray-200;
  }
}

input[type="range"] {
  --range-progress: 0;
  -webkit-appearance: none;
  position: relative;
  background: #a0aec0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  cursor: pointer;
}

input[type="range"]::-moz-range-track {
  position: relative;
  background: #a0aec0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  cursor: pointer;
}

input[type="range"]::before {
  content: "";
  height: 3px;
  background: #fff;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

input[type="range"]::-moz-range-progress {
  background: #fff;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

input[type="range"]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  border: transparent;
  position: relative;
}
input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
}

.swiper-pagination-bullet {
  background-color: white !important;
  display: inline-block;
  margin-top: 2em;
  color: gray !important;
}
.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
  background-color: white !important;
}
.swiper-pagination-bullet-active-prev {
  background-color: gray !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
